import { Fragment, useEffect } from "react";

import { ReactComponent as ClutchIcon } from "../assets/img/clutch_icon.svg";
import MailchimpForm from "./MailchimpForm";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t, i18n } = useTranslation();

  const currentLanguage = i18n.language;

  useEffect(() => {
    const script = document.createElement("script");

    script.src = "https://widget.clutch.co/static/js/widget.js";
    script.type = "text/javascript";

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const footerNavigation = {
    mexico: [
      { name: "" },
      { name: "hola@alluxi.com", href: "mailto:hola@alluxi.com" },
      // { name: "+52-(818)-421-9480", href: "tel:+52-(818)-421-9480" },
      { name: "+52-(812)-027-8245", href: "tel:+52-(812)-027-8245" },
    ],
    usa: [
      { name: "" },
      { name: "(512) 547-3628", href: "tel:(512) 547-3628" },
    ],
    about: [
      {
        name: t("contact"),
        href: currentLanguage === "en" ? "/contact" : "/es/contact",
      },
      {
        name: t("privacy-policy"),
        href:
          currentLanguage === "en" ? "/privacy-policy" : "/es/privacy-policy",
      },
    ],
    links0: [
      {
        name: t("react-software-development"),
        href:
          currentLanguage === "en"
            ? "/technology/react-software-development"
            : "/es/technology/react-software-development",
      },
      {
        name: t("python-software-development"),
        href:
          currentLanguage === "en" ? "/technology/python-software-development" :
          "/es/technology/python-software-development",
      },
      {
        name: t("vue-software-development"),
        href:
          currentLanguage === "en" ? "/technology/vue-software-development" :
          "/es/technology/vue-software-development",
      }
    ],
    links1: [
      {
        name: t("custom-web-develop"),
        href:
          currentLanguage === "en"
            ? "/services/custom-web-development"
            : "/es/services/custom-web-development",
      },
      {
        name: t("custom-mobile-develop"),
        href:
          currentLanguage === "en"
            ? "/services/custom-mobile-development"
            : "/es/services/custom-mobile-development",
      },
      {
        name: t("nearshore-services"),
        href:
          currentLanguage === "en"
            ? "/services/nearshore-development"
            : "/es/services/nearshore-development",
      },
      {
        name: t("user-experience"),
        href:
          currentLanguage === "en"
            ? "/services/ux-ui-services"
            : "/es/services/ux-ui-services",
      },
      {
        name: t("cloud-services"),
        href:
          currentLanguage === "en"
            ? "/services/cloud-services"
            : "/es/services/cloud-services",
      },
      {
        name: t("ai-integration"),
        href:
          currentLanguage === "en"
            ? "/services/ai-integration-services"
            : "/es/services/ai-integration-services",
      },
      {
        name: t("finance"),
        href:
          currentLanguage === "en"
            ? "/industry/finance"
            : "/es/industry/finance",
      },
      {
        name: t("health-care"),
        href:
          currentLanguage === "en"
            ? "/industry/healthcare"
            : "/es/industry/healthcare",
      },
      {
        name: t("education"),
        href:
          currentLanguage === "en"
            ? "/industry/education"
            : "/es/industry/education",
      },
    ],
    social: [
      {
        name: "LinkedIn",
        href: "https://mx.linkedin.com/company/alluxi-mx",
        icon: (props) => (
          <svg className="fill-agray-500" viewBox="0 0 19 19" {...props}>
            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
          </svg>
        ),
      },
      {
        name: "X.com",
        href: "https://x.com/alluximx",
        icon: (props) => (
          <svg className="fill-agray-500" viewBox="0 0 24 24" {...props}>
            <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
          </svg>
        ),
      },
      {
        name: "Facebook",
        href: "https://facebook.com/alluxi/",
        icon: (props) => (
          <svg fill="fill-agray-500" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },

      {
        name: "Instagram",
        href: "https://www.instagram.com/alluxi.mx/",
        icon: (props) => (
          <svg className="fill-agray-500" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "GitHub",
        href: "https://github.com/alluximx",
        icon: (props) => (
          <svg className="fill-agray-500" viewBox="0 0 24 24" {...props}>
            <path
              fillRule="evenodd"
              d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z"
              clipRule="evenodd"
            />
          </svg>
        ),
      },
      {
        name: "Youtube",
        href: "https://www.youtube.com/@alluxi",
        icon: (props) => (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="currentColor"
            className="bi bi-youtube"
            viewBox="0 0 16 16"
          >
            <path d="M8.051 1.999h.089c.822.003 4.987.033 6.11.335a2.01 2.01 0 0 1 1.415 1.42c.101.38.172.883.22 1.402l.01.104.022.26.008.104c.065.914.073 1.77.074 1.957v.075c-.001.194-.01 1.108-.082 2.06l-.008.105-.009.104c-.05.572-.124 1.14-.235 1.558a2.007 2.007 0 0 1-1.415 1.42c-1.16.312-5.569.334-6.18.335h-.142c-.309 0-1.587-.006-2.927-.052l-.17-.006-.087-.004-.171-.007-.171-.007c-1.11-.049-2.167-.128-2.654-.26a2.007 2.007 0 0 1-1.415-1.419c-.111-.417-.185-.986-.235-1.558L.09 9.82l-.008-.104A31.4 31.4 0 0 1 0 7.68v-.123c.002-.215.01-.958.064-1.778l.007-.103.003-.052.008-.104.022-.26.01-.104c.048-.519.119-1.023.22-1.402a2.007 2.007 0 0 1 1.415-1.42c.487-.13 1.544-.21 2.654-.26l.17-.007.172-.006.086-.003.171-.007A99.788 99.788 0 0 1 7.858 2h.193zM6.4 5.209v4.818l4.157-2.408L6.4 5.209z" />
          </svg>
        ),
      },

      {
        name: "Clutch",
        href: "https://clutch.co/profile/alluxi",
        icon: "",
      },
    ],
  };
  useEffect(() => {
    const isSpanish = window.location.pathname.includes("/es");
    console.log(isSpanish);
    if (isSpanish) {
      i18n.changeLanguage("es");
    } else {
      i18n.changeLanguage("en");
    }
  }, [i18n]);

  return (
    <Fragment>
      <MailchimpForm />
      <footer className="bg-agray-50">
        <div className="px-4 md:max-w-3xl lg:max-w-5xl xl:max-w-7xl mx-auto">
          <div className="pt-16 pb-8">
            <div className="grid grid-cols-2 gap-8 py-6 lg:py-8 md:grid-cols-5">
              <div>
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  México
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.mexico.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.href}
                        className="text-base text-agray-500 hover:text-ablue-500"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  United States
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.usa.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.href}
                        className="text-base text-agray-500 hover:text-ablue-500"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  {t("about-footer")}
                </h3>
                <ul className="mt-4 space-y-4">
                  {footerNavigation.about.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.href}
                        className="text-base text-agray-500 hover:text-ablue-500"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
                
                <ul className="mt-12 space-y-4">
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  {t("technologies")}
                </h3>
                  {footerNavigation.links0.map((item, index) => (
                    <li key={index}>
                      <a
                        href={item.href}
                        className="text-base text-agray-500 hover:text-ablue-500"
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  Links
                </h3>
                <ul className=" ">
                  {footerNavigation.links1.map((item, index) => (
                    <div className=" mt-4 md:order-2" key={index}>
                      <li key={index}>
                        <a
                          key={index}
                          href={item.href}
                          className="col-span-1 text-agray-500 hover:text-ablue-500"
                          rel="noreferrer"
                        >
                          {item.name}
                        </a>
                      </li>
                    </div>
                  ))}
                </ul>
              </div>
              <div>
                <h3 className="text-md font-semibold text-nblue-900 tracking-wider">
                  {t("follow")}
                </h3>

                <ul className="grid grid-cols-7 gap-x-12 md:gap-x-12 md:grid-cols-3  w-3/4 lg:grid-cols-4  ">
                  {footerNavigation.social.map((item, index) => (
                    <div className=" mt-4 md:order-2" key={index}>
                      <a
                        key={index}
                        href={item.href}
                        className="col-span-1 text-agray-500 hover:text-ablue-500"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <span className="sr-only">{item.name}</span>
                        {item.icon !== "" ? (
                          <item.icon
                            className="col-span-2 h-7 w-7 fill-agray-500 hover:fill-ablue-500"
                            aria-hidden="true"
                          />
                        ) : (
                          <div className="col-span-2  text-agray-500 hover:text-ablue-500">
                            <ClutchIcon className="h-6 w-6" />
                          </div>
                        )}
                      </a>
                    </div>
                  ))}
                </ul>
                <ul>
                  <a href="https://www.austintechnologycouncil.org/company/alluxi/" target="_blank" rel="noreferrer">
                    <img className="w-40 mt-10" src={require("../assets/img/atc.png")} alt="Austin Technology Council Member" />
                  </a>
                </ul>
                <ul className=" w-full">
                  <div
                    className="clutch-widget mt-10 min-w-full"
                    data-url="https://widget.clutch.co"
                    data-widget-type="2"
                    data-height="100"
                    min-width="250px"
                    data-nofollow="true"
                    data-expandifr="true"
                    data-scale="100"
                    data-clutchcompany-id="736921"
                  ></div>
                </ul>
              </div>
            </div>
            <div className="mt-[56px] border-agray-200  md:flex md:items-center md:justify-between md:mt-[48px]">
              <p className="text-base text-agray-500 md:mt-0 md:order-1">
                Alluxi &copy; 2024 {t("right-reserved")}
              </p>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}
