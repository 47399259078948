import BlogSuggestions from "./BlogSuggestions";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function BlogMain() {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;

  return (
    <div className="bg-white py-16">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="text-center mb-16">
          <span className="text-sm md:text-md tracking-widest font-semibold text-ablue-600 font-bold uppercase">
            BLOG
          </span>
          <h2 className="text-3xl md:text-4xl xl:text-5xl text-nblue-900 font-bold mt-2">
            {t("blog-title")}
          </h2>
          <p className="font-light mt-4 text-base md:text-lg text-agray-500 max-w-2xl mx-auto">
            {t("blog-desc")}
          </p>
        </div>

        <div className="space-y-8">
          <BlogSuggestions />

          <div className="flex justify-center">
            <Link
              to={currentLanguage === "en" ? "/blog" : "/es/blog"}
              className="inline-flex items-center justify-center px-8 py-3 rounded-full text-sm font-manrope text-white bg-gradient-to-r from-ablue-500 to-ablue-900 bg-animate-slow hover:from-agray-500 hover:to-agray-500 transition-all duration-300 shadow-sm"
            >
              {t("see-all-post")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
