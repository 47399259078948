import { useState, useEffect } from 'react';

export default function VideoPlaylist({ playlistId }) {
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const API_KEY = process.env.REACT_APP_YOUTUBE_API_KEY; // You'll need to add this to your .env file
    
    const fetchPlaylistVideos = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `https://www.googleapis.com/youtube/v3/playlistItems?part=snippet&maxResults=10&playlistId=${playlistId}&key=${API_KEY}`
        );

        if (!response.ok) {
            console.log(response);
          throw new Error('Failed to fetch playlist: ' + response.status);
        }

        const data = await response.json();
        
        const formattedVideos = data.items.map(item => ({
          id: item.id,
          videoId: item.snippet.resourceId.videoId,
          title: item.snippet.title,
          thumbnail: item.snippet.thumbnails.medium,
          description: item.snippet.description
        }));

        setVideos(formattedVideos);
      } catch (err) {
        console.error('Error fetching playlist:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (playlistId) {
      fetchPlaylistVideos();
    }
  }, [playlistId]);

  if (loading) {
    return <div className="text-center p-4">Loading videos...</div>;
  }

  if (error) {
    return <div className="text-red-500 p-4">Error: {error}</div>;
  }

  return (
    <div className="space-y-4">
      {videos.map((video) => (
        <div key={video.id} className="relative" style={{ paddingBottom: '56.25%' }}>
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            src={`https://www.youtube.com/embed/${video.videoId}`}
            title={video.title}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </div>
      ))}
    </div>
  );
} 