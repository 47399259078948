import { Fragment, useEffect, useRef, useState, useCallback } from "react";
import { Helmet } from "react-helmet";
import Loading from "./shared/Loading";
import ReCAPTCHA from "react-google-recaptcha";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import aluxiLogo from "../assets/img/logo.svg";
import { ArrowRightIcon } from '@heroicons/react/solid';

export default function Contact(props) {
  const { t, i18n } = useTranslation();

  const [isDisabled, setIsDisabled] = useState(true);
  const [emailValid, setEmailValid] = useState(false);
  const [emailTouched, setemailTouched] = useState(false);
  const [messageValid, setMessageValid] = useState(false);
  const [messageTouched, setMessageTouched] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [selectedIndustry, setSelectedIndustry] = useState("");

  const [errorMessage, setErrorMessage] = useState("error-contact-us");

  const nameInputRef = useRef();
  const emailInputRef = useRef();
  const messageInputRef = useRef();
  const industryInputRef = useRef();

  const captcha = useRef();

  const currentLanguage = i18n.language;
  

  const navigate = useNavigate();

  const onChange = (e) => {
    if (messageInputRef.current) {
      if (messageInputRef.current.value !== "") {
        setMessageTouched(true);
      }

      let validationMessage = messageInputRef.current.value.length > 10;
      setMessageValid(validationMessage);
    }

    if (emailInputRef.current.value !== "") {
      setemailTouched(true);
      let re =
        /^(([^<>()\]\\.,;:\s@"]+(\.[^<>()\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (re.test(emailInputRef.current.value)) {
        setEmailValid(true);
      } else {
        setEmailValid(false);
      }
    }
    if (emailInputRef.current.value === "") {
      setEmailValid(false);
    }
  };

  const checkFormValidity = useCallback(() => {
    if (
      nameInputRef.current.value !== "" &&
      emailInputRef.current.value !== "" &&
      emailValid === true &&
      emailTouched === true &&
      messageValid === true &&
      messageInputRef.current.value !== "" &&
      selectedIndustry !== ""
    ) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  }, [emailValid, emailTouched, messageValid, selectedIndustry]);

  useEffect(() => {
    checkFormValidity();
  }, [checkFormValidity]);

  const validationEmail = emailTouched && !emailValid;

  const onSubmitHandler = (e) => {
    setLoading(true);
    e.preventDefault();

    if (captcha.current.getValue()) {
      const data = {
        senderEmail: e.target.Email.value,
        subject: "Contact message",
        body: e.target.Message.value,
        name: e.target.Name.value,
        selectedServices: selectedIndustry,
      };

      setTimeout(() => {
        nameInputRef.current.value = "";
        emailInputRef.current.value = "";
        messageInputRef.current.value = "";
        setSelectedIndustry("");
        setSent(1);
        setIsDisabled(true);
        setemailTouched(false);
        setLoading(false);

        window.gtag('event', 'conversion', {
          'send_to': 'AW-972900822/JvlkCIaomtYBENaT9c8D'
        });
        window.gtag("event", "conversion", {});
        window.hj("event", "conversion", {});

        navigate(currentLanguage === "en" ? `/thankyou` : `/es/thankyou`);
      }, 1500);

      const xhr = new XMLHttpRequest();
      xhr.open("POST", process.env.REACT_APP_MAIL_URL);

      xhr.setRequestHeader("x-api-key", process.env.REACT_APP_MAIL_API_KEY);
      xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8");

      xhr.onreadystatechange = function (e) {
        if (xhr.readyState === 4) {
          if (xhr.status !== 200) {
            console.error('Contact form API error:', xhr.status, xhr.responseText);
          }
        }
      };

      xhr.send(JSON.stringify(data));
    } else {
      setSent(2);
      setErrorMessage("error-recaptcha");
      setLoading(false);
    }
  };

  useEffect(() => {
    i18n.changeLanguage(props.language);
  }, [props.language, i18n]);

  const industries = [
    "fintech-insurance",
    "real-estate",
    "healthcare",
    "travel",
    "ecommerce-retail",
    "education",
    "other"
  ];

  const handleIndustryChange = (industry) => {
    setSelectedIndustry(industry);
  };

  return (
    <Fragment>
      <Helmet>
        <title>{t("contact")} | Alluxi </title>
        <link rel="canonical" href={window.location.href} />
        <meta name="description" content={t("contact-desc")} />
        <meta property="og:title" content={t("contact")} />
        <meta property="og:type" content="article" />
        <meta property="og:description" content={t("contact-desc")} />
        <meta property="og:url" content={window.location.href} />
      </Helmet>
      <div className="relative min-h-screen overflow-hidden">
        {/* Animated gradient background */}
        <div className="absolute inset-0 bg-gradient-to-br from-ablue-100 via-ablue-200 to-nblue-300 animate-gradient-x"></div>
        
        {/* Increase the top padding here */}
        <div className="relative m-auto mx-4 lg:mx-24">
          <div className="mx-auto grid grid-cols-1 md:grid-cols-2 gap-8 h-full">
            <div className="col-span-1 bg-white bg-opacity-90 p-8 rounded-lg backdrop-blur-sm h-full flex flex-col justify-between">
              <div>
                {/* Update the logo to be a clickable link */}
                <Link to={currentLanguage === "en" ? "/" : "/es"}>
                  <img src={aluxiLogo} alt="Alluxi Logo" className="h-12 mb-6 cursor-pointer" />
                </Link>
                <h1 className="text-4xl xl:text-5xl text-nblue-900 font-bold mb-6 flex items-center">
                  {t("contact")}
                  <ArrowRightIcon className="h-8 w-8 ml-2 text-ablue-600 mt-2 ml-5 " />
                </h1>
                <p className="text-lg text-agray-500 mb-8">
                  {t("contact-desc")}
                </p>
              </div>
              <div className="flex justify-center">
                <img
                  src={require("../assets/img/home/contactdesc.png")}
                  alt="Contact Us"
                  className="w-10/12 rounded-lg"
                />
              </div>
            </div>
            <div className="col-span-1 bg-white bg-opacity-90 p-8 rounded-lg backdrop-blur-sm h-full">
              <form
                onSubmit={onSubmitHandler}
                method="POST"
                className="flex flex-col justify-between mt-24"
              >
                <div>
                  <div className="mb-8">
                    <label
                      htmlFor="name"
                      className="block text-md text-nblue-900 font-bold mb-2 text-left"
                    >
                      {t("name")}
                    </label>
                    <input
                      id="name"
                      name="Name"
                      type="text"
                      ref={nameInputRef}
                      onChange={onChange}
                      autoComplete="given-name"
                      placeholder={t("enter-name")}
                      className="w-full px-4 py-2 bg-agray-100 text-nblue-900 rounded-md focus:ring-nblue-500 focus:border-nblue-500"
                      data-hj-allow
                    />
                  </div>
                  <div className="mb-8">
                    <label
                      htmlFor="email"
                      className="block text-md text-nblue-900 font-bold mb-2 text-left"
                    >
                      {t("email")}
                    </label>
                    <input
                      id="email"
                      name="Email"
                      type="email"
                      ref={emailInputRef}
                      onChange={onChange}
                      autoComplete="email"
                      placeholder={t("enter-email")}
                      className="w-full px-4 py-2 bg-agray-100 text-nblue-900 rounded-md focus:ring-nblue-500 focus:border-ablue-300"
                      data-hj-allow
                    />
                    {validationEmail && (
                      <p className="text-nblue-900 italic font-bold text-sm mt-1 text-left">{t("invalid-email")}</p>
                    )}
                  </div>
                  <div className="mb-8">
                    <label
                      htmlFor="Message"
                      className="block text-md text-nblue-900 font-bold mb-2 text-left"
                    >
                      {t("message")}
                    </label>
                    <textarea
                      id="how-can-we-help"
                      name="Message"
                      placeholder={t("enter-message")}
                      rows={4}
                      onChange={onChange}
                      ref={messageInputRef}
                      className="w-full px-4 py-2 bg-agray-100 text-nblue-900 rounded-md focus:ring-nblue-500 focus:border-ablue-300"
                      data-hj-allow
                    ></textarea>
                    {!messageValid && messageTouched && (
                      <p className="text-nblue-900 italic font-bold text-sm mt-1 text-left">{t("invalid-message")}</p>
                    )}
                  </div>
                  <div className="mb-8">
                    <label
                      htmlFor="industry"
                      className="block text-md text-nblue-900 font-bold mb-2 text-left"
                    >
                      {t("industry")}
                    </label>
                    <div className="flex flex-wrap gap-2">
                      {industries.map((industry) => (
                        <label key={industry} className="inline-flex items-center">
                          <input
                            type="radio"
                            name="Industry"
                            value={industry}
                            checked={selectedIndustry === industry}
                            onChange={() => handleIndustryChange(industry)}
                            className="hidden"
                            ref={industryInputRef}
                          />
                          <span className={`px-3 py-1 rounded-full text-sm cursor-pointer transition-colors duration-200 ${
                            selectedIndustry === industry
                              ? 'bg-ablue-600 text-white'
                              : 'bg-agray-100 text-nblue-900 hover:bg-agray-200'
                          }`}>
                            {t(`industry-${industry}`)}
                          </span>
                        </label>
                      ))}
                    </div>
                  </div>
                </div>
                <div>
                  <ReCAPTCHA
                    sitekey={process.env.REACT_APP_CAPTCHA_KEY}
                    ref={captcha}
                    className="mb-6"
                  />
                  {loading ? (
                    <div className="flex justify-center">
                      <Loading />
                    </div>
                  ) : (
                    <div className="text-center">
                      <button
                        disabled={isDisabled}
                        type="submit"
                        className={`w-full py-3 px-6 border border-transparent text-sm font-medium rounded-full text-white ${
                          isDisabled
                            ? "bg-agray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-nblue-500"
                            : "bg-ablue-600 hover:bg-ablue-700"
                        }`}
                      >
                        {t("send")}
                      </button>
                      {sent === 1 && (
                        <p className="font-light text-center mt-4 text-lg text-agray-500">
                          {t("thanks-contacting-us")}
                        </p>
                      )}
                      {sent === 2 && (
                        <p className="font-bold text-center mt-4 text-lg text-nblue-900">
                          {t(errorMessage)}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
